import "core-js/stable";
import "regenerator-runtime/runtime";
import Swiper, { Navigation, Pagination, Autoplay, EffectFade } from "swiper";
import "swiper/css";
Swiper.use([Navigation, Pagination, Autoplay, EffectFade]);
import lazySizes from "lazysizes";
import Menu from "./js/Menu";

import "./app.scss";

window.addEventListener("load", () => {
  console.log("page loaded -- insert code below");

  Menu(document.querySelectorAll(".menu-btn"));
  // this is always at the bottom - no code beyond this call

  new Swiper(".js-swiper-container-header", {
    // If we need pagination
    pagination: {
      el: ".swiper-pagination",
    },
    autoplay: {
      delay: 2500,
      disableOnInteraction: true,
    },
    effect: "fade",

    // Navigation arrows
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },

    // And if we need scrollbar
    scrollbar: {
      el: ".swiper-scrollbar",
    },
  });
});
